import * as React from "react"
import { styled } from "@mui/material/styles"
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActionArea,
  Collapse,
  Avatar,
  IconButton,
  IconButtonProps,
  Typography,
} from "@mui/material"
import { red } from "@mui/material/colors"

import FavoriteIcon from "@mui/icons-material/Favorite"
import ShareIcon from "@mui/icons-material/Share"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import MoreVertIcon from "@mui/icons-material/MoreVert"

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))

interface Props {
  data: {
    id: string | number
    author: string
    date: string
    image: string
    excerpt: string
    url: string
    title: string
  }
}

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = "#"

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}`,
  }
}

export default function RecipeReviewCard(props: Props) {
  return (
    <Card sx={{ maxWidth: 345, height: "100%" }}>
      <CardActionArea
        component="a"
        href={props.data.url}
        sx={{ textDecoration: "none !important" }}
      >
        <CardHeader
          avatar={<Avatar {...stringAvatar(props.data.author)} />}
          title={props.data.title}
          subheader={props.data.date}
        />
        <CardMedia
          component="img"
          height="194"
          image={props.data.image}
          alt={props.data.title}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {props.data.excerpt}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
