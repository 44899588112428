import React from "react"
import { Container, Box, Typography, Grid } from "@mui/material"

import Title from "../../components/Title"
import Footer from "../../components/Footer"
import BlogCard from "../../components/BlogCard"

const blogs = [
  {
    id: "abxgs89",
    author: "Swati Sinha",
    date: "MAR 29, 2024",
    title: "5 Tips to make an impression during Interview",
    excerpt:
      "Interviews are the most formal events we engage in multiple times during our careers. You want to leave the best impression about yourself.",
    image:
      "https://savannahr.com/blog/content/images/size/w960/2024/03/work-life.jpg",
    url:
      "https://savannahr.com/blog/5-tips-to-make-an-impression-during-interview/",
  },
  {
    id: "abxgs89",
    author: "Swati Sinha",
    date: "MAR 29, 2024",
    title: "Best Tips about Interviewing For a Job",
    excerpt:
      "Millions of jobs posted online but it's difficult to crack one. This is the thought that strikes our mind when you start looking for a job. Job hunting is a painful process",
    image:
      "https://savannahr.com/blog/content/images/size/w960/2024/03/work-life.jpg",
    url: "https://savannahr.com/blog/best-tips-about-interviewing-for-a-job/",
  },
]

export default () => {
  return (
    <div className="App">
      <Title
        title="INTERVIEW TIPS"
        subTitle="How to Prepare for your interview?"
      />
      <Container maxWidth="xl">
        <Grid container spacing={2} mb={4}>
          {blogs.map(blog => (
            <Grid item key={`blog-${blog.id}`}>
              <BlogCard data={blog} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </div>
  )
}
